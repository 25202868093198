body {
  margin: 0;
  font-family: sans-serif, "遊ゴシック", "Yu Gothic", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 48px;
  letter-spacing: -0.07em;
}

h2 {
  font-size: 24px;
  letter-spacing: -0.032em;
}

h3 {
  font-size: 20px;
  letter-spacing: -0.024em;
  line-height: 1.5;
}

h4 {
  font-size: 16px;
  line-height: 1.3;
}

p {
  font-size: 16px;
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: black;
}

button {
  font-size: 14px;
  padding: 0 16px;
  border: none;
  background-color: rgb(29, 89, 255);
  color: white;
  font-weight: medium;
  cursor: pointer;
  border-radius: 999px;
  height: 42px;
  max-width: 160px;
  // animation
  transition: all 0.13s ease-in-out;

  &:hover {
    background-color: rgb(38, 0, 255);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: rgb(124, 211, 255);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}